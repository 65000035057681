import React from 'react';
import { useNavigate  } from 'react-router-dom';
import { useEffect, useCallback, useState } from "react";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import axios from "axios";

const CheckoutFlutterwavePayment = () => {
  let navigate = useNavigate();
  const [successulPayment, setSuccessulPayment] = useState<boolean>(false);
  // const baseApi = "http://localhost:4000/";
  const baseApi     = "http://test-api-v2.brickpine.com:4000/";
  const queryString = window.location.search;
  const urlParams   = new URLSearchParams(queryString);
  const username    = String(urlParams.get('username'));
  const amount1     = String(urlParams.get('amount'));
  const email       = String(urlParams.get('email'));
  const phone_number = String(urlParams.get('phone_number'));
  const name        = String(urlParams.get('name'));
  const title       = String(urlParams.get('title'));
  const description = String(urlParams.get('description'));
  const currency    = String(urlParams.get('currency'));
  
  const config = {
    public_key: 'FLWPUBK_TEST-0c8998ca1b212c0c71389ef6d74d3be4-X',
    tx_ref: String(Date.now()),
    // amount:  37,
    amount:  parseFloat(amount1),
    currency: String(currency),
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: String(email),
      phone_number: phone_number,
      name: String(name),
    },
    customizations: {
    title: String(title),
    description: String(description),
    logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };


  const handleFlutterPayment = useFlutterwave(config);

  const handleFlutterPaymentCallback = useCallback(() => {
    handleFlutterPayment({
      callback: (response) => {
          console.log("----------------response-----------");
          console.log(response);
          if(response.status === "successful") {
            paymentCompleted(response);
            // setDisplayButton(true);
          }
          closePaymentModal() // this will close the modal programmatically
      },
      onClose: () => {},
    });
  }, [handleFlutterPayment]);

  useEffect(() => {
    handleFlutterPaymentCallback();
  }, [handleFlutterPaymentCallback]);

  const paymentCompleted = async (paymentDetails: any) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const saveSubscriptionTransactionUrl = `${baseApi}transaction/save_subscription_transaction`;
    axios({
    method: 'post',
    url: saveSubscriptionTransactionUrl,
    // headers: {
    //     'Authorization': `Bearer ${token}`,
    // },
    data: {
        "username": username,
        "currency": paymentDetails.currency,
        "amount": paymentDetails.amount,
        "chargedAmount": paymentDetails.charged_amount,
        "email": paymentDetails.customer.email,
        "name": paymentDetails.customer.name,
        "phoneNumber": paymentDetails.customer.phone_number,
        "paymentCompanyRef": paymentDetails.flw_ref,
        "transactionId": paymentDetails.transaction_id,
        "txRef": paymentDetails.tx_ref,
        "paymentCompany": "Flutterwave",
        "paymentType": "subscription"
    }
    })
    .then(response => {
        // setSuccessulPayment(true);
        window.location.replace(`http://frontend-web.brickpine.com/post_message.html?username=${username}&amount=${paymentDetails.amount}&charge_response_code=${paymentDetails.charge_response_code}&charge_response_message=${paymentDetails.charge_response_message}&charged_amount=${paymentDetails.charged_amount}&created_at=${paymentDetails.created_at}&currency=${paymentDetails.currency}&email=${paymentDetails.customer.email}&name=${paymentDetails.customer.name}&phone_number=${phone_number}&flw_ref=${paymentDetails.flw_ref}&status=${paymentDetails.status}&transaction_id=${paymentDetails.transaction_id}&tx_ref=${paymentDetails.tx_ref}`);
        // navigate(`/payment_successful?username=${username}&amount=${paymentDetails.amount}&charge_response_code=${paymentDetails.charge_response_code}&charge_response_message=${paymentDetails.charge_response_message}&charged_amount=${paymentDetails.charged_amount}&created_at=${paymentDetails.created_at}&currency=${paymentDetails.currency}&email=${paymentDetails.customer.email}&name=${paymentDetails.customer.name}&phone_number=${paymentDetails.customer.phone_number}&flw_ref=${paymentDetails.flw_ref}&status=${paymentDetails.status}&transaction_id=${paymentDetails.transaction_id}&tx_ref=${paymentDetails.tx_ref}`);
    })
    .catch(function (error) {
      console.error(error);
    });
  }

  const sendMessageToFlutter = () => {
    const displayFromFlutter = document.getElementById('displayFromFlutter');
    if (displayFromFlutter) {
      displayFromFlutter.innerHTML = 'tester 1';
    }
    // Send a message to Flutter
    (window as any).flutter.postMessage('transaction_successful');
  };

  return (
    <>
    </>
  );
};

export default CheckoutFlutterwavePayment;