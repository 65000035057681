import React from 'react';
import { BrowserRouter, Route, Routes  } from 'react-router-dom';
import Home from './pages/Home';
import OrderFlutterwavePayment from './pages/payment/order/OrderFlutterwavePayment';
import SubscriptionFlutterwavePayment from './pages/payment/subscription/SubscriptionFlutterwavePayment';
import PaymentSuccessful from './pages/payment/subscription/PaymentSuccessful';
import CheckoutFlutterwavePayment from './pages/payment/subscription/CheckoutFlutterwavePayment';

function App() {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/"  element={<SubscriptionFlutterwavePayment />}/>
            <Route path="/order_pay_with_flutterwave" element={<OrderFlutterwavePayment />}/>
            <Route path="/subscription_pay_with_flutterwave" element={<SubscriptionFlutterwavePayment />}/>
            <Route path="/checkout_pay_with_flutterwave" element={<CheckoutFlutterwavePayment />}/>
            <Route path="/payment_successful" element={<PaymentSuccessful />}/>
            </Routes>
        </BrowserRouter>
    );
  }

export default App;

