import React, { useEffect } from 'react';

const PaymentSuccessful = () => {
  // const baseApi = "http://localhost:4000/";
  const baseApi = "http://test-api-v2.brickpine.com:4000/";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const username                = String(urlParams.get('username'));
  const charge_response_code    = String(urlParams.get('charge_response_code'));
  const charge_response_message = String(urlParams.get('charge_response_message'));
  const charged_amount          = String(urlParams.get('charged_amount'));
  const created_at    = String(urlParams.get('created_at'));
  const currency      = String(urlParams.get('currency'));
  const email         = String(urlParams.get('email'));
  const name          = String(urlParams.get('name'));
  const phone_number    = String(urlParams.get('phone_number'));
  const flw_ref         = String(urlParams.get('flw_ref'));
  const status          = String(urlParams.get('status'));
  const transaction_id  = String(urlParams.get('transaction_id'));
  const tx_ref          = String(urlParams.get('tx_ref'));

  useEffect(() => {
    // Include the Flutter JavaScript channel
    (window as any).flutter = {
      postMessage: function (message: string) {
        // Send message to Flutter
        (window as any).toFlutter.postMessage(message);
      },
    };

    // Receive messages from Flutter
    (window as any).toFlutter = {
      postMessage: function (message: string) {
        // Handle messages from Flutter
        console.log('Message from Flutter:', message);
        // Perform actions based on the message received from Flutter
        alert('Message from Flutter: ' + message);
      },
    };
  }, []);

  const sendMessageToFlutter = () => {
    alert('Keep running');
    const displayFromFlutter = document.getElementById('displayFromFlutter');
    if (displayFromFlutter) {
      displayFromFlutter.innerHTML = 'tester 1';
    }
    // Send a message to Flutter
    (window as any).flutter.postMessage('transaction_successful');
  };



  return (
    <div>
      <h1>Web Page 1</h1>
      <h3 id="displayFromFlutter"></h3>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
    </div>
  );
};

export default PaymentSuccessful;