import React from 'react';
import { useEffect, useCallback, useState } from "react";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import axios from "axios";

const OrderFlutterwavePayment = () => {
  // const baseApi = "http://localhost:4000/";
  const baseApi = "http://test-api-v2.brickpine.com:4000/";
  const [orderId, setOrderId] = useState<boolean>(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const amount1 = String(urlParams.get('amount'));
  const email = String(urlParams.get('email'));
  const phone_number = String(urlParams.get('phone_number'));
  const name = String(urlParams.get('name'));
  const title = String(urlParams.get('title'));
  const description = String(urlParams.get('description'));
  const currency = String(urlParams.get('currency'));
  
  const cartId = String(urlParams.get('cartId'));
  const billingAddressId = String(urlParams.get('billingAddressId'));
  const logisticUsername = String(urlParams.get('logisticUsername'));
  const deliveryDate = String(urlParams.get('deliveryDate'));
  const deliveryTime = String(urlParams.get('deliveryTime'));

  const config = {
    public_key: 'FLWPUBK_TEST-0c8998ca1b212c0c71389ef6d74d3be4-X',
    tx_ref: String(Date.now()),
    amount:  parseFloat(amount1),
    currency: String(currency),
    payment_options: 'card,mobilemoney,ussd',
    customer: {
    email: String(email),
    phone_number: String(phone_number),
    name: String(name),
    },
    customizations: {
    title: String(title),
    description: String(description),
    logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  useEffect(() => {
    const initiatePayment = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')
        axios({
        method: 'post',
        url: `${baseApi}cart/move_to_order`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "cartId": cartId,
            "billingAddressId": billingAddressId,
            "logisticUsername": logisticUsername,
            "deliveryDate": deliveryDate,
            "deliveryTime": deliveryTime
        }
        })
        .then(response => {
        console.log(response.data);
        setOrderId(response.data)
        })
        .catch(function (error) {
        console.error(error);
        });
    }

    initiatePayment();
  }, []);


  const handleFlutterPayment = useFlutterwave(config);

  const handleFlutterPaymentCallback = useCallback(() => {
    handleFlutterPayment({
      callback: (response) => {
          console.log("----------------response-----------");
          console.log(response);
          if(response.status = "successful") {
              paymentCompleted(response);
              // setDisplayButton(true);
          }
          closePaymentModal() // this will close the modal programmatically
      },
      onClose: () => {},
    });
  }, [handleFlutterPayment]);

  useEffect(() => {
    // alert("123")
    handleFlutterPaymentCallback();
  }, [handleFlutterPaymentCallback]);

  const paymentCompleted = async (paymentDetails: any) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')
    axios({
    method: 'post',
    url: `${baseApi}transaction/save_transaction`,
    headers: {
        'Authorization': `Bearer ${token}`,
    },
    data: {
        "orderTbId": orderId,
        "currency": paymentDetails.currency,
        "amount": paymentDetails.amount,
        "chargedAmount": paymentDetails.charged_amount,
        "email": paymentDetails.customer.email,
        "name": paymentDetails.customer.name,
        "phoneNumber": paymentDetails.customer.phone_number,
        "paymentCompanyRef": paymentDetails.flw_ref,
        "transactionId": paymentDetails.transaction_id,
        "txRef": paymentDetails.tx_ref,
        "paymentCompany": "Flutterwave",
        "paymentType": "order"
    }
    })
    .then(response => {
        console.log(response.data);
    })
    .catch(function (error) {
        console.error(error);
    });
}

  return (
    <>
    </>
  );
};

export default OrderFlutterwavePayment;
